import React, { useState } from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {ReplacePlaceholders, TransformJsonText} from "./Tools";

export function MainConsent(props) {
    const { t, i18n } = useTranslation();
    console.log('main_consent');
    console.log(props.main_consent);
    return (
        <div className="consent-row">
            <div className="consent-checkbox-col">
                <input type={"checkbox"} checked={props.main_consent.checked} onChange={() => props.handle()}/>
            </div>
            <div className="consent-description-col">
                <div className="consent-title">
                    {ReplacePlaceholders(props.main_consent.localized_title)}
                    {/*{(props.main_consent.localized_link !== '' &&  props.main_consent.localized_link_label)&&

                        <>&nbsp;<a href={props.main_consent.localized_link} target="_blank">{props.main_consent.localized_link_label}</a></>

                    }*/}
                </div>
                <div className="consent-text">
                    <TransformJsonText  data = {props.main_consent.localized_text} id={props.main_consent.id}/>

                </div>
                {(props.main_consent.localized_link !== '' &&  props.main_consent.localized_link_label)&&
                <div className="consent-text">
                    <a href={props.main_consent.localized_link} target="_blank">{props.main_consent.localized_link_label}</a>
                </div>
                }
            </div>
            <div className="flex-tools">
                <a className="nl-link uppercase" onClick={() => props.showFullHandle()}>
                    {props.showFull ? <>{t('welcome_hide_button')}<RemoveIcon/></>: <>{t('welcome_expand_button')}<AddIcon/></>}
                </a>
            </div>
        </div>
    );
}

export function SelfConsent(props) {
    return (
        <div key={props.consent.id} className="consent-row sub_consent-row">
            <div className="consent-checkbox-col">
                <input type={"checkbox"} checked={props.consent.checked} onChange={() => props.selfConsentChange(props.consent.id)}/>
            </div>
            <div className="consent-description-col">
                <div className="consent-title">
                    {ReplacePlaceholders(props.consent.localized_title)}
                </div>
                <div className="consent-text">
                    <TransformJsonText  data = {props.consent.localized_text} id = {props.consent.id}/>

                </div>
            </div>
        </div>
    );
}

export function PartnerConsents(props) {

    const organization_rows = props.partner_consent.organizations.map((partner) => {
        return (
            <PartnerConsentOrganizationRow partner={partner} key={partner.name}/>

        );
    })
    return (
        <div className="consent-row sub_consent-row">
            <div className="consent-checkbox-col">
                {organization_rows.length>0 &&
                    <input type={"checkbox"} checked={props.partner_consent.checked} onChange={() => props.partnerConsentChange()}/>
                }
            </div>
            <div className="partner-consent-container">
                {organization_rows}
            </div>

        </div>
    );
}

function PartnerConsentOrganizationRow(props) {
    const rows = props.partner.consents.map((consent) => {
        return <PartnerConsentRow consent={consent} key={consent.id}/>
    });
    return (
        <div className="consent-description-col">
            <div className="consent-title">{props.partner.name}</div>
            <div className="consent-text">
                {rows}
            </div>
        </div>
    );
}

function PartnerConsentRow(props) {
    return (
        <div className="partner-consent-sub-row">
            <div className="sub-consent-title">
                {props.consent.localized_title}
            </div>
            <div className="sub-consent-text">
                <TransformJsonText  data = {props.consent.localized_text} id = {props.consent.id}/>
            </div>
        </div>
    );
}

