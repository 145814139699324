
import React, { useState, useEffect, useContext } from 'react';
import '../Styles/App.css';
import {Trans, useTranslation} from "react-i18next";
import {ErrorPage, NinjaLoader,useSuggestionSet, useSuggestionEdit, secondsToDays} from "./Tools";
import Api from "./api";
import {useHistory} from "react-router-dom";
import {BorrowerContext} from "./App";
import {OurPartners} from "./Welcome"
import {LoanRequisites} from "./Loan";
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import Slider from '@mui/material/Slider'
import {SingleSuggestionInput} from './Suggestions';
import {SkipButton} from "./HeaderTools";

function LoanReview() {
    const { t, i18n } = useTranslation();

    const borrower_context_data = useContext(BorrowerContext);

    const [loan_data, setLoanData]   = useState({});
    const [isLoading, setIsLoading]  = useState(true);
    const [error_resp, setError]     = useState(null);
    const [min_term, setMinTerm]     = useState(0);
    const [max_term, setMaxTerm]     = useState(0);
    const [min_amount, setMinAmount] = useState(0);
    const [max_amount, setMaxAmount] = useState(0);

    const [selected_term, setSelectedTerm]               = useState(0);
    const [selected_amount, setSelectedAmount]           = useState(0);
    const [suggestions, setSuggestions]                  = useState([]);
    const [slider_term_errors, setSliderTermErrors]      = useState([]);

    const [slider_amount_errors, setSliderAmountErrors] = useState([]);

    let current_element = null;

    let history = useHistory();


    function daysToSeconds(days) {
        return (days * (3600 * 24));
    }

    useEffect(() => {
        if(loan_data.loan !== undefined) {
            changeSelectedAmount(loan_data.loan.amount);
            changeSelectedTerm(secondsToDays(loan_data.loan.term));
        }
    }, [loan_data])

    useEffect(() => {
        borrower_context_data.setActiveStep(1);
    },[]);

    const canOpen =  () => {
        console.log('************CAN_OPEN');
        console.log(current_element);
        if(current_element === null) {
            return true;
        } else {
            //console.log(current_element.input);
            if(current_element.input) {
                current_element.input.focus();
            } else if(current_element.numberInputRef) {
                current_element.numberInputRef.focus();
            } else {
                current_element.focus();
            }
            return false;
        }

    }

    const registerOpenedElement = element => {
        current_element = element;
    }

    const clearElement = () => {
        current_element = null;
    }


    useEffect(() => {
        function getLoanData() {
            setIsLoading(true);
            Api.post('/loan/get-full', {"params":{"id":borrower_context_data.loan.id}})
                .then(
                    (result) => {
                        let response = result.data;
                        if(response.code === 0) {
                            setLoanData(response.data);


                            let loan = borrower_context_data.loan;
                            loan.stage = response.data.loan.stage;
                            borrower_context_data.fillLoan(loan);

                            setMinTerm(Number(
                                response.data.term_and_amount_suggestions.term.min > response.data.loan.term ?
                                    secondsToDays(response.data.loan.term) :
                                    secondsToDays(response.data.term_and_amount_suggestions.term.min)
                            ));

                            setMaxTerm(Number(
                                response.data.term_and_amount_suggestions.term.max < response.data.loan.term ?
                                    secondsToDays(response.data.loan.term) :
                                    secondsToDays(response.data.term_and_amount_suggestions.term.max)
                            ));

                            setMinAmount(Number(
                                response.data.term_and_amount_suggestions.amount.min > response.data.loan.amount ?
                                    response.data.loan.amount :
                                    response.data.term_and_amount_suggestions.amount.min
                            ));

                            setMaxAmount(Number(
                                response.data.term_and_amount_suggestions.amount.max < response.data.loan.amount ?
                                    response.data.loan.amount :
                                    response.data.term_and_amount_suggestions.amount.max
                            ));

                            setSuggestions(response.data.suggestions);

                        } else {
                            if(response.errors[0].code === -3012003) {
                                setError({
                                    title: 'no_loan',
                                    message: 'no_loan_founded',
                                    action: ''
                                });
                            } else {
                                setError({
                                    title: 'oops',
                                    message: 'something_wrong',
                                    action: ''
                                });
                            }
                        }
                        setIsLoading(false);

                    },

                    (error) => {
                        setIsLoading(false);
                        setError({
                            title: 'oops',
                            message: 'something_wrong',
                            action: ''
                        });
                    }
                )
        }
        getLoanData();
    }, [i18n.language]);

    const onSuggestionChange = (id, value) => {

        console.log('suggestion_change_______'+id+'_______'+value);
        console.log(suggestions);

        const new_suggestions = JSON.parse(JSON.stringify(suggestions))
        for(let i=0; i<new_suggestions.length; i++) {
            if(new_suggestions[i].id === id){
                new_suggestions[i].is_filled = true;
                new_suggestions[i].filled_value = value;
            }
        }
        console.log(new_suggestions);
        setSuggestions(new_suggestions);

    }

    const changeSelectedAmount = (amount) => {
        let safe_amount = 0;
        if(isNaN(amount)){
            safe_amount = amount.replace(/\D/g,'');
        } else {
            safe_amount = amount;
        }

        console.log('CHANGE_SELECTED_AMOUNT___'+amount);
        console.log(safe_amount)
        console.log(loan_data.term_and_amount_suggestions);
        setSelectedAmount(safe_amount);

        let errors = [];

        if(safe_amount < loan_data.term_and_amount_suggestions.amount.min) {

            errors.push(<div key="0" className="nl-error-text">
                <Trans
                    i18nKey="loan_review_amount_low_error"
                    values={{
                        min: loan_data.term_and_amount_suggestions.amount.min
                    }}
                    components={[]}
                />
            </div>);
        } else {
            if(safe_amount > loan_data.term_and_amount_suggestions.amount.max){
                errors.push(<div key="1" className="nl-error-text">
                    <Trans
                        i18nKey="loan_review_amount_high_error"
                        values={{
                            max: loan_data.term_and_amount_suggestions.amount.max
                        }}
                        components={[]}
                    />
                </div>);
            }
        }


        setSliderAmountErrors(errors);
    }

    const changeSelectedTerm = (term) => {
        let safe_term = 0;
        if(isNaN(term)){
            safe_term = term.replace(/\D/g,'');
        } else {
            safe_term = term;
        }
        setSelectedTerm(safe_term);

        let errors = [];

        if(safe_term < secondsToDays(loan_data.term_and_amount_suggestions.term.min)){
            errors.push(<div key="0" className="nl-error-text">
                <Trans
                    i18nKey="loan_review_term_low_error"
                    values={{
                        min: secondsToDays(loan_data.term_and_amount_suggestions.term.min)
                    }}
                    components={[]}
                />
            </div>);
        } else {
            if(safe_term > secondsToDays(loan_data.term_and_amount_suggestions.term.max)){
                errors.push(<div key="1" className="nl-error-text">
                    <Trans
                        i18nKey="loan_review_term_high_error"
                        values={{
                            max: secondsToDays(loan_data.term_and_amount_suggestions.term.max)
                        }}
                        components={[]}
                    />
                </div>);
            }
        }



        setSliderTermErrors(errors);
    }

    const submitReview = () => {
        if(Number(loan_data.loan.amount) !== Number(selected_amount) ||
            Number(secondsToDays(loan_data.loan.term)) !== Number(selected_term)){
            setIsLoading(true);
            Api.post('/loan/set-amount-and-term', {
                "params":{"id" : loan_data.loan.id, "amount" : selected_amount, "term" : daysToSeconds(selected_term)}
            })
                .then(
                    (result) => {
                        let response = result.data;
                        if(response.code === 0){
                            history.push('/distributing');
                        } else {
                            setError({
                                title: 'oops',
                                message: 'something_wrong',
                                action: ''
                            });
                        }
                        setIsLoading(false);
                    },
                    (error) => {
                        setError({
                            title: 'oops',
                            message: 'something_wrong',
                            action: ''
                        });
                        setIsLoading(false);
                    }
                )
        } else {
            history.push('/distributing');
        }

    }


    if (error_resp)
    {
        return <ErrorPage {...error_resp}/>
    }
    else
    {
        if(isLoading) {
            return (<NinjaLoader />);
        } else {
            return (
                <div>
                    <div><h1>{t('loan_review_title')}</h1></div>

                    <div className="suggestion-hint">
                        <LightbulbOutlinedIcon className="tools-icon"/>

                        <Trans
                            i18nKey="loan_review_tip"
                            values={{
                                min_amount: loan_data.term_and_amount_suggestions.amount.min,
                                max_amount: loan_data.term_and_amount_suggestions.amount.max,
                                min_term: secondsToDays(loan_data.term_and_amount_suggestions.term.min),
                                max_term: secondsToDays(loan_data.term_and_amount_suggestions.term.max)
                            }}
                            components={[<span className="blue-mark-text" />]}
                        />

                    </div>
                    <div className="loan-review-term-amount">

                        <div className="flex-row-container gap-21">
                            <div>
                                <div className="nl-select-title">{t('loan_review_choose_amount')}</div>
                                <div className="input-icon input-icon-right">
                                    <input className={slider_amount_errors.length>0 ? "input-error" : ""} type="text" onChange={(e) => changeSelectedAmount(e.target.value)} value={selected_amount}/>
                                    <i>{process.env.REACT_APP_CURRENCY}</i>
                                </div>
                                <Slider
                                    value={typeof Number(selected_amount) === 'number' ? Number(selected_amount) : 0}
                                    aria-label="Amount"
                                    onChange={(e,val)=>changeSelectedAmount(val)}
                                    min={min_amount}
                                    step={100}
                                    max={max_amount}
                                    className="nl-slider"
                                />

                            </div>
                            <div>
                                <div className="nl-select-title">{t('loan_review_choose_term')}</div>
                                <div className="input-icon input-icon-right">
                                    <input className={slider_term_errors.length>0 ? "input-error" : ""} type="text" onChange={(e) => changeSelectedTerm(e.target.value)} value={selected_term}/>
                                    <i>{t('days_measure_unit')}</i>
                                </div>
                                <Slider
                                    value={typeof Number(selected_term) === 'number' ? Number(selected_term) : 0}
                                    min={min_term}
                                    max={max_term}
                                    aria-label="Term"
                                    onChange={(e,val)=>changeSelectedTerm(val)}
                                    className="nl-slider"
                                />
                            </div>

                        </div>
                        {(slider_amount_errors.length>0 || slider_term_errors.length>0) &&
                            <div className="nl-error-text-block">
                                {slider_amount_errors}
                                {slider_term_errors}
                            </div>
                        }
                    </div>
                    <div><LoanRequisites loan={loan_data.loan}/></div>
                    <LoanReviewSuggestionsList
                        suggestions={suggestions}
                        setError = {setError}
                        registerOpenedElement = {registerOpenedElement}
                        canOpen = {canOpen}
                        clearElement = {clearElement}
                        onAnswer = {onSuggestionChange}
                    />
                    <div className="flex-center-row-container top-margin bottom-margin">
                        <button className="nl-button" disabled={(slider_amount_errors.length>0 || slider_term_errors.length>0)} onClick={submitReview}>{t('loan_review_send_button')}</button>
                    </div>
                    <div className="nl-tooltip"> {t('loan_review_send_tip_1')} </div>
                    <div className="nl-tooltip"> {t('loan_review_send_tip_2')} </div>
                    <OurPartners/>
                </div>
            );
        }

    }

}
export default LoanReview;

export function LoanReviewSingleSuggestion (props) {
    const [answer_value, setAnswerValue] = useState('');
    const [is_visible, setIsVisible] = useState(false);
    const [is_hover, setIsHover] = useState(false);
    const [is_edit, setIsEdit] = useState(false);
    const { t, i18n } = useTranslation();

    const Submitter = useSuggestionSet;
    const EditSubmitter = useSuggestionEdit;
    const submitSuggestion = function() {

        const onSuccess = function(data) {
            props.suggestion.is_filled = true;
            props.suggestion.filled_value = answer_value;
            props.onAnswer(props.suggestion.id, answer_value);
            closeElement();
        }
        if(is_edit === true) {
            EditSubmitter(answer_value,props.suggestion.id,onSuccess,props.setError);
        } else {
            Submitter(answer_value,props.suggestion.id,onSuccess,props.setError);
        }

    }
    const editValue = function(value) {
        setIsEdit(true);
        setAnswerValue(value);
        openElement();
    }

    const setValue = function() {
        setIsEdit(false);
        openElement();
    }

    const openElement = function() {
        if(props.canOpen() === true){
            setIsVisible(true)
        }
    }

    const closeElement = function() {
        setIsVisible(false);
        props.clearElement();
    }

    const hoverIn = function() {
        setIsHover(true);
    }

    const hoverOut = function() {
        setIsHover(false);
    }



    const changeAnswer = (value) => {
        setAnswerValue(value);
        console.log('CHANGED');
        console.log(value);
        console.log(answer_value);
    }

    const transformValue = (value) => {
        if(props.suggestion.requisite.kind === 3) {
            let dateParts = value.split("-");
            return ""+dateParts[2]+"."+dateParts[1]+"."+dateParts[0]+"";
        }
        if(props.suggestion.requisite.kind === 4) {
            if(value === true) {
                return t('select_true');
            } else {
                return t('select_false');
            }
        }
        return value;
    }


    return (
        <div className="flex-row-container suggestion-row"
             onMouseEnter = {hoverIn}
             onMouseLeave = {hoverOut}
        >
            <div>
                <div className="suggestion-row-title">{props.suggestion.requisite.suggestion_question}</div>
                <div className="suggestion-row-data">
                    {is_visible === true &&
                        <SingleSuggestionInput
                            current_suggestion={props.suggestion}
                            onChange={changeAnswer}
                            value = {answer_value}
                            registerRef = {el => props.registerOpenedElement(el)}
                            is_edit={is_edit}
                        />
                    }
                    {(is_visible === false && props.suggestion.is_filled === true) &&
                        transformValue(props.suggestion.filled_value)
                    }
                    {(is_visible === false && props.suggestion.is_filled === false) &&
                        <span className="grayed-text"> {t('loan_review_not_answered')} </span>
                    }
                </div>
            </div>
            <div>
                {is_hover === true &&
                    <>
                        {(is_visible === false && props.suggestion.is_filled === false ) &&
                            <button onClick={setValue} className="nl-veiled-button blue-text">{t('loan_review_answer_button')}</button>
                        }
                        {(is_visible === false && props.suggestion.is_filled === true ) &&
                            <button onClick={() => editValue(props.suggestion.filled_value)}  className="nl-veiled-button blue-text">{t('loan_review_edit_button')}</button>
                        }
                    </>
                }
                {is_visible === true && <>
                    <button onClick={closeElement} className="nl-veiled-button">{t('loan_review_cancel_button')}</button>
                    <button onClick={submitSuggestion} className="nl-veiled-button blue-text">{t('loan_review_save_button')}</button>
                </>}
            </div>
        </div>
    );
}

export function LoanReviewSuggestionsList (props) {
    const { t, i18n } = useTranslation();

    const [unfilled_suggestions, setUnfilledSuggestions] = useState('');
    const [suggestion_rows, setSuggestionRows] = useState(false);

    useEffect(() => {

    let rows = [];
    let unfilled_count = 0;
    for(let i=0; i<props.suggestions.length; i++){
        if(props.suggestions[i].is_filled === false) {
            unfilled_count++;
        }
        rows.push(<LoanReviewSingleSuggestion
            key = {props.suggestions[i].id}
            suggestion = {props.suggestions[i]}
            setError = {props.setError}
            registerOpenedElement = {props.registerOpenedElement}
            canOpen = {props.canOpen}
            clearElement = {props.clearElement}
            onAnswer = {props.onAnswer}
        />);
    }
    setUnfilledSuggestions(unfilled_count);
    setSuggestionRows(rows);


    }, [props.suggestions]);

    return (
      <>
          <div className="suggestion-hint">
              <LightbulbOutlinedIcon className="tools-icon"/>
              {unfilled_suggestions === 0 &&
              <>
                  {t('loan_review_all_suggestions_filled')}
              </>
              }
              {unfilled_suggestions > 0 && <>
                  <Trans
                      i18nKey="loan_review_suggestion_tip" // the key in your JSON file
                      values={{ // The variables in your i18n entry from the JSON file
                          question_number: unfilled_suggestions
                      }}
                      components={[<span className="blue-mark-text" />]}
                  />
              </>}
          </div>
          <div>
              <div className="loan-suggestions">
                  {suggestion_rows}
              </div>
          </div>
      </>
    );
}
