import React, {useContext, useEffect, useState} from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import Api from "./api";
import {ErrorPage, NinjaLoader, secondsToDays} from "./Tools";
import {LoanReviewSingleSuggestion} from "./LoanReview";
import {BorrowerContext} from "./App";
import Feedback from "./Feedback";

function ClearLoanData() {
    const { t, i18n } = useTranslation();

    const borrower_context_data = useContext(BorrowerContext);
    const [isLoading, setIsLoading]  = useState(true);
    const [isCleared, setIsCleared]  = useState(false);
    const [error_resp, setError]     = useState(null);

    useEffect(() => {
        borrower_context_data.setActiveStep(2);
    },[]);
    useEffect(() => {
        function getLoanData() {
            setIsLoading(true);
            Api.post('/loan/clean-up', {"params":{"id":borrower_context_data.loan.id}})
                .then(
                    (result) => {
                        let response = result.data;
                        if(response.code === 0) {
                            let loan = borrower_context_data.loan;
                            loan.stage = 8;
                            borrower_context_data.fillLoan(loan);
                        } else {
                            switch (response.errors[0].code) {
                                case -3010705:
                                    setIsCleared(true);
                                    break;

                                default:
                                    setError({
                                        title: 'oops',
                                        message: 'something_wrong',
                                        action: ''
                                    });
                                    break;
                            }
                        }

                        setIsLoading(false);

                    },

                    (error) => {
                        setIsLoading(false);
                        setError({
                            title: 'oops',
                            message: 'something_wrong',
                            action: ''
                        });
                    }
                )
        }
        if(borrower_context_data.loan.stage === 8) {
            setIsLoading(false);
        } else {
            getLoanData();
        }

    }, []);


    if (error_resp)
    {
        return <ErrorPage {...error_resp}/>
    }
    else {
        if (isLoading) {
            return (<NinjaLoader/>);
        } else {
            return (
                <div className="error-container">
                    <div className="clear-data-title">{t('clear_loan_data_title')}</div>
                    <div className="clear-data-text">{t('clear_loan_data_text')}</div>
                    <div>
                        <Feedback point="4"/>
                    </div>
                </div>
            );
        }
    }

}

export default ClearLoanData;