import React from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";

function ContactUs() {
    return(

        <div>
            <h1>Contact us</h1>
            <div>contacts</div>
            <div>contacts</div>
        </div>
    );

}

export default ContactUs;