import React, { useState, useEffect, useContext } from 'react';
import {BorrowerContext} from "./App";
import {ErrorPage, NinjaLoader} from "./Tools";
import Api from './api';
import '../Styles/App.css';
import {
    useParams,
    useHistory, Link
} from "react-router-dom";



function AuthByHash() {

    let history = useHistory();
    const [error_resp, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const {auth_hash,redirect_to} = useParams();

    const borrower_context_data = useContext(BorrowerContext);

    const request_body = {
        params: {
            hash: auth_hash
        }
    };

    useEffect(() => {

        function fetchAuthByHash() {
            Api.post('/auth-by-hash',request_body)
                .then(
                    (result) => {
                        let response = result.data;
                        if (response.code === 0) {

                            borrower_context_data.fillBorrowerInfo(
                                response.data.borrower,
                                response.data.loan,
                                response.data.token
                            );
                            setIsLoaded(true);
                            setError(null);

                            if(redirect_to !== undefined) {
                                history.push('/'+redirect_to);
                            } else {

                                switch (response.data.loan.stage) {
                                    case 6:
                                        history.push('/decline');
                                        break;
                                    case 3:
                                    case 4:
                                    case 5:
                                    case 9:
                                        history.push('/distributing');
                                        break;
                                    case 8:
                                        history.push('/clear');
                                        break;
                                    default:
                                        if (response.data.loan.review_started === true) {
                                            history.push('/review');
                                        } else {
                                            if (response.data.loan.suggestion_requested === true) {
                                                history.push('/suggestions');
                                            } else {
                                                if (response.data.borrower.pd_consent === true) {
                                                    history.push('/loan');
                                                } else {
                                                    history.push('/welcome');
                                                }
                                            }
                                        }
                                        break;
                                }
                            }
                            } else {
                                switch(response.errors[0].code){
                                    case -3000204:
                                        setError({
                                            title: 'hash_not_found',
                                            message: 'hash_not_found',
                                            action: ''
                                        });
                                        setIsLoaded(true);
                                        break;

                                    case -3000203:
                                        setError({
                                            title: 'hash_expired',
                                            message: 'hash_expired',
                                            action: ''
                                        });
                                        setIsLoaded(true);
                                        break;
                                    case -3000205:
                                        setError({
                                            title: 'clear_loan_data_title',
                                            message: 'no_loan_founded',
                                            action: ''
                                        });
                                        setIsLoaded(true);
                                        break;
                                    default:
                                        setError({
                                            title: 'oops',
                                            message: 'something_wrong',
                                            action: ''
                                        });
                                        setIsLoaded(true);
                                        break;
                                }
                            }
                    },

                    (error) => {
                        setError({
                            title: 'oops',
                            message: 'something_wrong',
                            action: ''
                        });
                        setIsLoaded(true);
                    }
                )
        }
        fetchAuthByHash();
    }, []);

    if(isLoaded === false)
    {
        return <NinjaLoader />
    }
    else
    {
        if (error_resp)
        {
            return <ErrorPage {...error_resp}/>
        }
        else
        {
            return <div><Link to="/welcome">You may continue</Link></div>
        }
    }


}

export default AuthByHash;