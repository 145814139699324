import React, { useState} from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";


function Footer() {
    const { t, i18n } = useTranslation();
    return (
        <div className="nl-footer flex-row-container flex-centered">
            <div className="flex-col"><a href="https://ninjalender.net/" target="_blank">{t('footer_about')}</a></div>
            <div className="flex-col"><Link to="/faq">{t('footer_faq')}</Link></div>
            <div className="flex-col"><a href="mailto:system@ninjalender.net">{t('footer_contact_us')}</a></div>
        </div>
    );

}

export default Footer;