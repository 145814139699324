import React, { useState, useEffect, useContext } from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import Api from "./api";
import {ErrorPage, NinjaLoader} from "./Tools";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {TransformJsonText} from "./Tools";

export function Faq() {

    const [error_resp, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [categories, setCategories] = useState([]);
    const [active_category, setActiveCategory] = useState(0);
    const { t, i18n } = useTranslation();

    useEffect(() => {

        function fetchFaqData() {
            Api.defaults.headers.post['Ninja-Lang'] = i18n.language;
            Api.post('/faq/get',{})
            .then(
                (result) => {
                    let response = result.data;
                    if (response.code !== undefined && response.code === 0) {
                        setCategories(response.data);
                        let keys = Object.keys(response.data);
                        setActiveCategory(response.data[keys[0]].id);

                    } else {
                        setError({
                            title: 'Ooops',
                            message: 'Something wrong',
                            action: ''
                        });

                    }
                    setIsLoaded(true);
                },

                (error) => {
                    setError({
                        title: 'Ooops',
                        message: 'Something wrong',
                        action: ''
                    });
                    setIsLoaded(true);
                }
            )
        }
        fetchFaqData();
    }, [i18n.language]);

    /*const listCategories = categories.map((category) =>
        <FaqCategory key={category.id} category={category} />
    );*/

    function changeCategory(cat_id) {
        setActiveCategory(cat_id);
    }

    if(isLoaded === false) {
        return <NinjaLoader />
    } else {
        if (error_resp) {
            return <ErrorPage {...error_resp}/>
        } else {
            return (
                <div>
                    <h1>{t('faq_title')}</h1>
                    <div className="faq">
                        <div className="faq-menu">
                            {Object.keys(categories).map(function(key){

                                return <div className={active_category === categories[key].id ? 'faq-menu-item active' : 'faq-menu-item'} key={categories[key].id}>
                                    <a onClick={() => changeCategory(categories[key].id)}>{categories[key].localized_name}</a>
                                </div>;
                            })}
                        </div>
                        <div className="faq-content">
                            {categories[active_category].active_questions.map(function(question){
                                return <FaqQuestion question={question} key={question.id}/>
                            })}
                        </div>

                    </div>
                </div>
            );
        }
    }
}


export function FaqQuestion(props){
    const [show_answer, setShowAnswer] = useState(false);

    function toggleShow() {
        setShowAnswer(!show_answer);
    }
    return (
        <div className="faq-question-row">
            <div className="question-data">
                <div className="faq-question">{props.question.localized_name}</div>
                {show_answer && <div className="faq-answer">
                    <TransformJsonText  data = {props.question.localized_answer} id={props.question.id}/>
                </div>}
            </div>
            <div className="tools-icon">
                <a onClick={()=>toggleShow()}>
                    {show_answer ? <RemoveIcon/> : <AddIcon/>}

                </a>
            </div>

        </div>
    )

}
